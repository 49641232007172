import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import axios from "axios";
import { server, config } from "../../env";
import { useParams, Link } from "react-router-dom";

const UserData = (props) => {
  const { id } = useParams();
  const [OrderStats, setOrderStats] = useState({});
  const [invoiceStats, setInvoiceStats] = useState(0);
  const [totalSpent, setTotalSpent] = useState(0);
  const [invoices, setInvoices] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [limit, setLimit] = useState(15);

  const readOrderStats = async () => {
    await axios
      .get(server + "/api/v1/users/order-stats/" + id, config)
      .then((res) => {
        setOrderStats(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const readInvoices = async () => {
    await axios
      .get(
        server +
          "/api/v1/users/invoices/" +
          id +
          "?page=" +
          page +
          "&limit=" +
          limit,
        config
      )
      .then((res) => {
        setInvoices(res.data.invoices);
        setTotal(res.data.total);
        setTotalPages(res.data.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // read total deposits
  const readTotalDeposit = async () => {
    await axios
      .get(server + "/api/v1/users/deposits/" + id, config)
      .then((res) => {
        setInvoiceStats(res.data.totalDeposits);
        setTotalSpent(res.data.totalSpent);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    readOrderStats();
    readTotalDeposit();
  }, []);

  useEffect(() => {
    readInvoices();
  }, [page]);

  return (
    <div className="nk-wrap">
      <Header sidebarRef={props.sidebarRef} />
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  {/* back button */}
                  <Link to="/users" className="btn btn-icon btn-light px-3">
                    <em className="icon ni ni-arrow-left"></em>
                    Go Back
                  </Link>
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">User Details</h3>
                  </div>
                </div>
              </div>
              <div className="nk-block">
                <div className="row g-gs">
                  <div className="col-12 col-md-6 col-lg-4">
                    <div
                      className="card h-100"
                      style={{ borderRadius: "0.8rem" }}
                    >
                      <div className="card-inner">
                        <div className="d-flex flex-row justify-content-between">
                          <div className="flex flex-column">
                            <h5 className="text-gray">Total Orders</h5>
                            <h6 className="py-2">
                              {OrderStats.allTimeOrdersCount
                                ? OrderStats.allTimeOrdersCount
                                : 0}
                            </h6>
                          </div>
                          <div className="flex flex-column  ">
                            <em
                              style={{ fontSize: "2.5rem" }}
                              class="text text-primary icon ni ni-box-view-fill"
                            ></em>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-4">
                    <div
                      className="card h-100"
                      style={{ borderRadius: "0.8rem" }}
                    >
                      <div className="card-inner">
                        <div className="d-flex flex-wrap justify-content-between">
                          <div className="flex flex-column">
                            <h5 className="text-gray">Total Deposits</h5>
                            <h6 className="py-2">{"$" + invoiceStats}</h6>
                          </div>
                          <div className="d-flex flex-wrap p-1">
                            <em
                              style={{ fontSize: "2.5rem" }}
                              class="text text-primary icon ni ni-sign-usd"
                            ></em>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-4">
                    <div
                      className="card h-100"
                      style={{ borderRadius: "0.8rem" }}
                    >
                      <div className="card-inner">
                        <div className="d-flex flex-wrap justify-content-between">
                          <div className="flex flex-column">
                            <h5 className="text-gray">Total Spent</h5>
                            <h6 className="py-2">{"$" + totalSpent}</h6>
                          </div>
                          <div className="d-flex flex-wrap p-1">
                            <em
                              style={{ fontSize: "2.5rem" }}
                              class="text text-primary icon ni ni-sign-usd"
                            ></em>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nk-block ">
                <div className="card" style={{ borderRadius: "0.8rem" }}>
                  <div className="card-inner">
                    <div className="d-flex flex-wrap">
                      <div className="col-12 col-md-1 d-flex justify-content-center align-items-center">
                        <h5>Orders:</h5>
                      </div>
                      <div className="col-12 col-md-11  px-md-2 px-0 d-flex flex-wrap flex-fill mt-md-2 mt-0 mt-lg-0">
                        <div className="col-12 col-md-6 col-lg-4 mt-2 mt-md-0 mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Today</h6>
                                  <h5 className="py-2">
                                    {OrderStats.todayOrdersCount
                                      ? OrderStats.todayOrdersCount
                                      : 0}
                                  </h5>
                                </div>
                                <div className="flex flex-column  ">
                                  <em
                                    style={{ fontSize: "2.5rem" }}
                                    class="icon ni ni-cart"
                                  ></em>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mt-2 mt-md-0 mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Week</h6>
                                  <h5 className="py-2">
                                    {OrderStats.thisWeekOrdersCount
                                      ? OrderStats.thisWeekOrdersCount
                                      : 0}
                                  </h5>
                                </div>
                                <div className="flex flex-column  ">
                                  <em
                                    style={{ fontSize: "2.5rem" }}
                                    class="icon ni ni-cart"
                                  ></em>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mt-2  mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Month</h6>
                                  <h5 className="py-2">
                                    {OrderStats.thisMonthOrdersCount
                                      ? OrderStats.thisMonthOrdersCount
                                      : 0}
                                  </h5>
                                </div>
                                <div className="flex flex-column  ">
                                  <em
                                    style={{ fontSize: "2.5rem" }}
                                    class="icon ni ni-cart"
                                  ></em>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="nk-block mt-4">
            <div className="card" style={{ borderRadius: "0.8rem" }}>
              <div className="card-inner">
                <h5>Incoices</h5>
              </div>

              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">ID</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Payment Method</th>
                      <th scope="col">Status</th>
                      <th scope="col">Created At</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoices.map((invoice) => (
                      <tr key={invoice._id}>
                        <td>
                          {invoices.indexOf(invoice) + 1 + limit * (page - 1)}
                        </td>
                        <td>{invoice._id}</td>
                        <td>{"$ " + invoice.amount}</td>
                        <td>{invoice.payment_method}</td>
                        <td>
                          {invoice.status === "pending" ? (
                            <span className="badge badge-warning">
                              {invoice.status}
                            </span>
                          ) : invoice.status === "paid" ? (
                            <span className="badge badge-success">
                              {invoice.status}
                            </span>
                          ) : (
                            <span className="badge badge-danger">
                              {invoice.status}
                            </span>
                          )}
                        </td>
                        <td>{new Date(invoice.createdAt).toLocaleString()}</td>
                        <td>
                          {invoice.hosted_url ? (
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                window.open(invoice.hosted_url, "_blank");
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              View
                            </button>
                          ) : (
                            <>-</>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center my-3">
                <nav>
                  <ul className="pagination">
                    <li
                      className={
                        page === 1 ? "page-item disabled" : "page-item"
                      }
                    >
                      <Link
                        className="page-link"
                        to=""
                        onClick={(e) => {
                          setPage(page - 1);
                        }}
                      >
                        Prev
                      </Link>
                    </li>

                    <li className="page-item active" aria-current="page">
                      <Link className="page-link" to="">
                        {page} <span className="sr-only">(current)</span>
                      </Link>
                    </li>

                    <li
                      className={
                        page === totalPages ? "page-item disabled" : "page-item"
                      }
                    >
                      <Link
                        className="page-link"
                        to=""
                        tabIndex={-1}
                        aria-disabled={page === totalPages ? true : false}
                        onClick={(e) => {
                          setPage(page + 1);
                        }}
                      >
                        Next
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserData;
