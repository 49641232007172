import React, { useContext, useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { UserContext } from "../App";

const Sidebar = (props) => {
  const [tab, setTab] = useState(window.location.href.split("/")[3] || "");
  const userContextValue = useContext(UserContext);
  useEffect(() => {
    if (userContextValue && userContextValue.user.role === "staff") {
      setTab("invoices");
    } else {
      setTab("");
    }
  }, [userContextValue]);

  return (
    <div
      className="nk-sidebar nk-sidebar-fixed is-light"
      data-content="sidebarMenu"
      ref={props.sidebarRef}
    >
      <div className="nk-sidebar-element nk-sidebar-head">
        <div className="nk-sidebar-brand">
          <NavLink to="/" className="logo-link nk-sidebar-logo">
            <img className=" logo-img" />
          </NavLink>
        </div>
        <div className="nk-sidebar-brand">
          <NavLink to="/" className="logo-link nk-sidebar-logo">
            <img
              className=" logo-img"
              src="/assets/logo.png"
              alt="logo-small"
            />
          </NavLink>
        </div>
        <div className="nk-menu-trigger mr-n2">
          <a
            href="#"
            className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
            data-target="sidebarMenu"
            onClick={() =>
              props.sidebarRef.current?.classList.toggle("nk-sidebar-active")
            }
          >
            <em className="icon ni ni-arrow-left" />
          </a>
          <a
            href="#"
            className="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex"
            data-target="sidebarMenu"
            onClick={() =>
              props.sidebarRef.current?.classList.toggle("is-compact")
            }
          >
            <em className="icon ni ni-menu" />
          </a>
        </div>
      </div>
      {/* .nk-sidebar-element */}
      {userContextValue.user.role !== "staff" ? (
        <div className="nk-sidebar-element">
          <div className="nk-sidebar-content">
            <div className="nk-sidebar-menu" data-simplebar="">
              <ul className="nk-menu">
                <li className="nk-menu-heading">
                  <h6 className="overline-title text-primary-alt">Dashboard</h6>
                </li>
                {/* .nk-menu-item */}
                <li
                  onClick={() => {
                    setTab("");
                  }}
                  className={
                    tab === "" ? "nk-menu-item active" : "nk-menu-item "
                  }
                >
                  <NavLink to="/" className="nk-menu-link ">
                    <span className="nk-menu-icon ">
                      <em className="icon ni ni-activity-round-fill" />
                    </span>
                    <span className="nk-menu-text">Dashboard</span>
                  </NavLink>
                </li>
                <li
                  onClick={() => {
                    setTab("orders");
                  }}
                  className={
                    tab === "orders" ? "nk-menu-item active" : "nk-menu-item "
                  }
                >
                  <NavLink to="/orders" className="nk-menu-link ">
                    <span className="nk-menu-icon ">
                      <em className="icon ni ni-cc-fill" />
                    </span>
                    <span className="nk-menu-text">Orders</span>
                  </NavLink>
                </li>
                <li
                  onClick={() => {
                    setTab("bulk-orders");
                  }}
                  className={
                    tab === "bulk-orders"
                      ? "nk-menu-item active"
                      : "nk-menu-item "
                  }
                >
                  <NavLink to="/bulk-orders" className="nk-menu-link ">
                    <span className="nk-menu-icon ">
                      <em class="icon ni ni-box-view"></em>
                    </span>
                    <span className="nk-menu-text">Bulk Orders</span>
                  </NavLink>
                </li>
                <li
                  onClick={() => {
                    setTab("users");
                  }}
                  className={
                    tab === "users" ? "nk-menu-item active" : "nk-menu-item "
                  }
                >
                  <NavLink to="/users" className="nk-menu-link ">
                    <span className="nk-menu-icon ">
                      <em className="icon ni ni-users-fill" />
                    </span>
                    <span className="nk-menu-text">Users</span>
                  </NavLink>
                </li>

                <li
                  onClick={() => {
                    setTab("users");
                  }}
                  className={
                    tab === "new-users"
                      ? "nk-menu-item active"
                      : "nk-menu-item "
                  }
                >
                  <NavLink to="/new-users" className="nk-menu-link ">
                    <span className="nk-menu-icon ">
                      <em className="icon ni ni-users-fill" />
                    </span>
                    <span className="nk-menu-text">New Users</span>
                  </NavLink>
                </li>
                <li
                  onClick={() => {
                    setTab("staff");
                  }}
                  className={
                    tab === "staff" ? "nk-menu-item active" : "nk-menu-item "
                  }
                >
                  <NavLink to="/staff" className="nk-menu-link ">
                    <span className="nk-menu-icon ">
                      <em class="icon ni ni-user-list-fill"></em>
                    </span>
                    <span className="nk-menu-text">Staff</span>
                  </NavLink>
                </li>

                <li
                  onClick={() => {
                    setTab("invoices");
                  }}
                  className={
                    tab === "invoices" ? "nk-menu-item active" : "nk-menu-item "
                  }
                >
                  <NavLink to="/invoices" className="nk-menu-link ">
                    <span className="nk-menu-icon ">
                      <em className="icon ni ni-cc-alt2-fill" />
                    </span>
                    <span className="nk-menu-text">Invoices</span>
                  </NavLink>
                </li>

                <li
                  onClick={() => {
                    setTab("transaction");
                  }}
                  className={
                    tab === "transaction"
                      ? "nk-menu-item active"
                      : "nk-menu-item "
                  }
                >
                  <NavLink to="/transaction" className="nk-menu-link ">
                    <span className="nk-menu-icon ">
                      <em class="icon ni ni-tranx-fill"></em>
                    </span>
                    <span className="nk-menu-text">Transaction Logs</span>
                  </NavLink>
                </li>
                <li
                  onClick={() => {
                    setTab("usps");
                  }}
                  className={
                    tab === "usps" ? "nk-menu-item active" : "nk-menu-item "
                  }
                >
                  <NavLink to="/usps" className="nk-menu-link ">
                    <span className="nk-menu-icon ">
                      <em class="icon ni ni-tag-fill"></em>
                    </span>
                    <span className="nk-menu-text">USPS Tracking</span>
                  </NavLink>
                </li>

                <li
                  onClick={() => {
                    setTab("scansheet");
                  }}
                  className={
                    tab === "scansheet"
                      ? "nk-menu-item active"
                      : "nk-menu-item "
                  }
                >
                  <NavLink to="/scansheet" className="nk-menu-link ">
                    <span className="nk-menu-icon ">
                      <em class="icon ni ni-tag-fill"></em>
                    </span>
                    <span className="nk-menu-text">USPS Scansheet</span>
                  </NavLink>
                </li>

                <li
                  onClick={() => {
                    setTab("pricing");
                  }}
                  className={
                    tab === "pricing" ? "nk-menu-item active" : "nk-menu-item "
                  }
                >
                  <NavLink to="/pricing" className="nk-menu-link ">
                    <span className="nk-menu-icon ">
                      <em className="icon ni ni-money" />
                    </span>
                    <span className="nk-menu-text"> Label Pricing</span>
                  </NavLink>
                </li>
                <li
                  className={
                    tab === "website" ? "nk-menu-item active" : "nk-menu-item"
                  }
                  onClick={() => setTab("website")}
                >
                  <NavLink
                    to="/website"
                    className="nk-menu-link "
                    onClick={() =>
                      props.sidebarRef.current?.classList.toggle(
                        "nk-sidebar-active"
                      )
                    }
                  >
                    <span className="nk-menu-icon ">
                      <em className="icon ni ni-globe" />
                    </span>
                    <span className="nk-menu-text">Website</span>
                  </NavLink>
                </li>
              </ul>

              {/* .nk-menu */}
            </div>
            {/* .nk-sidebar-menu */}
          </div>
          {/* .nk-sidebar-content */}
        </div>
      ) : (
        <div className="nk-sidebar-element">
          <div className="nk-sidebar-content">
            <div className="nk-sidebar-menu" data-simplebar="">
              <ul className="nk-menu">
                {/* .nk-menu-item */}
                <li
                  onClick={() => {
                    setTab("orders");
                  }}
                  className={
                    tab === "orders" ? "nk-menu-item active" : "nk-menu-item "
                  }
                >
                  <NavLink to="/orders" className="nk-menu-link ">
                    <span className="nk-menu-icon ">
                      <em className="icon ni ni-cc-fill" />
                    </span>
                    <span className="nk-menu-text">Orders</span>
                  </NavLink>
                </li>
                <li
                  onClick={() => {
                    setTab("bulk-orders");
                  }}
                  className={
                    tab === "bulk-orders"
                      ? "nk-menu-item active"
                      : "nk-menu-item "
                  }
                >
                  <NavLink to="/bulk-orders" className="nk-menu-link ">
                    <span className="nk-menu-icon ">
                      <em class="icon ni ni-box-view"></em>
                    </span>
                    <span className="nk-menu-text">Bulk Orders</span>
                  </NavLink>
                </li>
                <li
                  onClick={() => {
                    setTab("users");
                  }}
                  className={
                    tab === "users" ? "nk-menu-item active" : "nk-menu-item "
                  }
                >
                  <NavLink to="/users" className="nk-menu-link ">
                    <span className="nk-menu-icon ">
                      <em className="icon ni ni-users-fill" />
                    </span>
                    <span className="nk-menu-text">Users</span>
                  </NavLink>
                </li>

                <li
                  onClick={() => {
                    setTab("users");
                  }}
                  className={
                    tab === "new-users"
                      ? "nk-menu-item active"
                      : "nk-menu-item "
                  }
                >
                  <NavLink to="/new-users" className="nk-menu-link ">
                    <span className="nk-menu-icon ">
                      <em className="icon ni ni-users-fill" />
                    </span>
                    <span className="nk-menu-text">New Users</span>
                  </NavLink>
                </li>

                <li
                  onClick={() => {
                    setTab("invoices");
                  }}
                  className={
                    tab === "invoices" ? "nk-menu-item active" : "nk-menu-item "
                  }
                >
                  <NavLink to="/invoices" className="nk-menu-link ">
                    <span className="nk-menu-icon ">
                      <em className="icon ni ni-cc-alt2-fill" />
                    </span>
                    <span className="nk-menu-text">Invoices</span>
                  </NavLink>
                </li>
              </ul>

              {/* .nk-menu */}
            </div>
            {/* .nk-sidebar-menu */}
          </div>
          {/* .nk-sidebar-content */}
        </div>
      )}

      {/* .nk-sidebar-element */}
    </div>
  );
};

export default Sidebar;
