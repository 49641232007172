import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { server, config } from "../../env";
import Alert from "../../components/Alert";
import Loader from "../../components/Loader";

const CustomPricing = (props) => {
  const { id } = useParams();
  const [pricing, setPricing] = useState({});
  const [labelTypes, setLabelTypes] = useState([]);
  const [user, setUser] = useState({});
  const [labelTypeId, setLabelTypeId] = useState("");

  useEffect(() => {
    const readLabelTypes = async () => {
      await axios
        .get(server + "/api/v1/labeltype/read", config)
        .then((res) => {
          setLabelTypes(res.data.labelTypes);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    readLabelTypes();
    readUser();
  }, []);

  const readUser = async () => {
    await axios
      .get(server + "/api/v1/users/read/" + id, config)
      .then((res) => {
        setUser(res.data.user);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addCustomPricing = async (e) => {
    e.preventDefault();

    const data = {
      labelType: e.target.labelType.value,
      price: e.target.price.value,
      fromWeight: e.target.fromWeight.value,
      toWeight: e.target.toWeight.value,
    };

    await axios
      .put(server + "/api/v1/users/custom-pricing/" + user._id, data, config)
      .then((res) => {
        readUser();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteCustomPricing = async (index) => {
    const data = {
      index,
    };

    await axios
      .post(
        server + "/api/v1/users/custom-pricing/delete/" + user._id,
        data,
        config
      )
      .then((res) => {
        readUser();
      })
      .catch((err) => {
        console.log(err);
        Alert("error", err.response.data.message);
      });
  };

  return (
    <div className="nk-wrap">
      <Header sidebarRef={props.sidebarRef} />
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <Link to="/users" className="btn btn-sm btn-warning">
                  <em className="icon ni ni-arrow-left"></em> Go Back
                </Link>
                <div className="nk-block-between">
                  <div className="nk-block-head-content  w-100">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <h2 className="card-title">{pricing.name}</h2>
                      <button
                        className="btn btn-info"
                        data-toggle="modal"
                        data-target="#create"
                      >
                        <i className="bi-plus "></i> Add Price
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body px-0 pb-0">
          <table className="table ">
            <thead className="thead-light">
              <tr>
                <th>#</th>
                <th>labelType</th>
                <th>From Weight</th>
                <th>To Weight</th>
                <th>Price</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {user?.customPricing?.map((price, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{price.labelType}</td>
                  <td>{price.fromWeight}</td>
                  <td>{price.toWeight}</td>
                  <td>${price.price}</td>
                  <td>
                    {/* <button className="btn btn-primary me-2">Edit</button> */}
                    <button
                      className="btn btn-danger"
                      onClick={() => deleteCustomPricing(index)}
                    >
                      <i className="bi-trash-fill "></i>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div
        id="create"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Add weight
              </h5>
              <button
                type="button"
                className="btn-danger"
                data-dismiss="modal"
                aria-label="Close"
              >
                <em className="icon ni ni-cross" />
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={addCustomPricing}>
                <div className="form-group mb-3">
                  <label>LabelTypes</label>
                  <select className="form-control" name="labelType">
                    {labelTypes.map((labelType, index) => (
                      <option key={index} value={labelType.name}>
                        {labelType.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group mb-3">
                  <label>From Weight</label>
                  <input
                    type="number"
                    className="form-control"
                    name="fromWeight"
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label>To Weight</label>
                  <input
                    type="number"
                    className="form-control"
                    name="toWeight"
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label>Price</label>
                  <input
                    type="number"
                    className="form-control"
                    name="price"
                    required
                    step={0.01}
                  />
                </div>
                <div className="d-flex mt-2 justify-content-end ">
                  <button
                    type="button"
                    className="btn btn-white me-3"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomPricing;
