function LineChart(dom, payload) {
  var Chart = window.Chart;
  new Chart(dom, {
    type: "line",
    // dont show y axis below 0
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    },

    data: {
      labels: payload.labels,
      datasets: [
        {
          label: "Revenue",
          data: payload.data,
          fill: true,
          borderColor: "blue",
        },
      ],
    },
  });
  return Chart;
}

function PieChart(dom, data) {
  console.log(data.labelTypesData);
  var Chart = window.Chart;
  new Chart(dom, {
    type: "doughnut",
    options: {
      responsive: false,

      legend: {
        position: "top",
        FontFace: "Arial",
        // font size
        labels: {
          fontSize: 15,

          // font color
          fontColor: "white",
        },
      },
      animation: {
        animateScale: true,
        animateRotate: true,
      },
    },

    data: {
      labels: data?.labelTypesData?.map((label) => label.labelType),
      datasets: [
        {
          label: "Orders",

          backgroundColor: data?.labelTypesData?.map(
            () => "#" + Math.floor(Math.random() * 16777215).toString(16)
          ),
          data: data?.labelTypesData?.map((label) => label.count),
        },
      ],
    },
  });
  return Chart;
}
function PieChart1(dom, data) {
  console.log(data.labelTypesData);
  var Chart = window.Chart;
  new Chart(dom, {
    type: "doughnut",
    options: {
      responsive: false,

      legend: {
        position: "top",
        FontFace: "Arial",
        // font size
        labels: {
          fontSize: 15,

          // font color
          fontColor: "white",
        },
      },
      animation: {
        animateScale: true,
        animateRotate: true,
      },
    },

    data: {
      labels: data?.paymentGatewaysData?.map((label) => label.paymentGateway),
      datasets: [
        {
          label: "Orders",

          backgroundColor: data?.paymentGatewaysData?.map(
            () => "#" + Math.floor(Math.random() * 16777215).toString(16)
          ),

          data: data?.paymentGatewaysData?.map((label) => label.count),
        },
      ],
    },
  });
  return Chart;
}

function BarChart(dom, payload, bgColor, labelHeading) {
  var Chart = window.Chart;
  new Chart(dom, {
    type: "bar",
    options: {
      responsive: true,
      legend: {
        position: "top",
        FontFace: "Arial",
        // font size
        labels: {
          fontSize: 15,

          // font color
          fontColor: "white",
        },
      },
      animation: {
        animateScale: true,
        animateRotate: true,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              stepSize: 1,
            },
          },
        ],
      },
    },

    data: {
      labels: payload.labels,

      // dont show y axis below 0

      datasets: [
        {
          label: labelHeading,
          backgroundColor: bgColor,
          data: payload.data,
        },
      ],
    },
  });
  return Chart;
}

module.exports = { LineChart, PieChart, BarChart, PieChart1 };
