import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config } from "../env";
import Loader from "../components/Loader";
import Alert from "../components/Alert";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const Staff = (props) => {
  const [staff, setStaff] = useState([]);
  const [loader, setLoader] = useState("");
  const [viewType, setViewType] = useState("list");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [url, setUrl] = useState("/api/v1/admin/staff/read");
  const [message, setMessage] = useState("");
  const [deleteStaffId, setDeleteStaff] = useState({
    id: null,
    isDeleting: false,
    loader: null,
  });

  const getStaff = async (search) => {
    var link = "";
    if (search) {
      link = url + "?page=" + page + "&limit=" + limit + "&search=" + search;
    } else {
      link = url + "?page=" + page + "&limit=" + limit;
    }

    await axios
      .get(server + link, config)
      .then((res) => {
        setStaff(res.data.staff);
        // setTotalPages(res.data.totalPages);
        // setTotal(res.data.total);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const createStaff = async (e) => {
    e.preventDefault();

    setLoader(<Loader />);

    const data = {
      username: e.target.username.value,
      password: e.target.password.value,
    };

    await axios
      .post(server + "/api/v1/admin/staff/create", data, config)
      .then((res) => {
        setLoader("");
        setMessage(<Alert className="success" message={res.data.message} />);
        getStaff();
        setTimeout(() => {
          closeModal();
        }, 2000);
      })
      .catch((err) => {
        setLoader("");
        setMessage(<Alert className="danger" message={err.response.data.message} />);
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };
  const deleteStaffMember = async (id) => {
    setDeleteStaff({ ...deleteStaffId, isDeleting: true });
    await axios
      .delete(server + "/api/v1/admin/staff/delete/" + id, config)
      .then((res) => {
        toast.success(res.data.message);
        setDeleteStaff({ id: null });
        getStaff();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setDeleteStaff({ id: null });
      });
  };

  const closeModal = () => {
    const modal = document.getElementById("modalClose");
    modal.click();
  };

  useEffect(() => {
    if (deleteStaffId.id) {
      deleteStaffMember(deleteStaffId.id);
    }
  }, [deleteStaffId.id]);

  useEffect(() => {
    getStaff();
  }, [page, limit]);

  const Card = ({ user }) => {
    return (
      <div className="col-12 col-md-6 col-lg-3">
        <div className="card ">
          <div className="card-inner p-1">
            <div className="card-body d-flex flex-column">
              <div className="d-flex flex-row  border-bottom p-1 pb-2 mb-2">
                <em style={{ fontSize: "4rem" }} class="icon ni ni-user-circle"></em>
                <div className="d-flex flex-column flex-md-row flex-wrap justify-content-between align-items-start w-100 m-1">
                  <h5>{user.username}</h5>
                  <button disabled={user._id === deleteStaffId.id} className="btn btn-primary">
                    <em
                      onClick={() => {
                        setDeleteStaff({ id: user._id });
                      }}
                      style={{ fontSize: "1.2rem" }}
                      class="icon ni ni-trash-fill"
                    ></em>
                  </button>
                </div>
              </div>
              <div className="d-flex flex-row flex-wrap justify-content-between">
                <h6 className="text-gray text-uppercase" style={{ fontSize: "0.8rem" }}>
                  {user._id}
                </h6>
                <h6 className="text-gray text-uppercase" style={{ fontSize: "0.8rem" }}>
                  Role : {user.role}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="nk-wrap">
      <Header sidebarRef={props.sidebarRef} />
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">Staff Members</h3>
                  </div>
                </div>
              </div>
              <div className="nk-block">
                <div className="row ">
                  <div className="col-md-12">
                    <div className="card shadow-sm">
                      <div className="card-inner">
                        <div className="card-header d-flex flex-column flex-md-row justify-content-between">
                          <button
                            className="btn btn-secondary px-5 py-1 text-center d-flex justify-content-center"
                            data-toggle="modal"
                            data-target="#add"
                          >
                            Create Staff
                          </button>
                          <div class="btn-group border mt-3 mt-md-0" aria-label="Basic example">
                            <button
                              onClick={() => {
                                setViewType("list");
                              }}
                              type="button"
                              class={"btn " + (viewType === "list" && "btn-primary")}
                            >
                              <em class="icon ni ni-list-index-fill"></em>
                            </button>
                            <button
                              onClick={() => {
                                setViewType("grid");
                              }}
                              type="button"
                              class={"btn " + (viewType === "grid" && "btn-primary")}
                            >
                              <em class="icon ni ni-view-x5"></em>
                            </button>
                          </div>
                        </div>
                        <div className="card-body">
                          {viewType === "list" ? (
                            <div className="table-responsive">
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th>ID</th>

                                    <th>Username</th>

                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {staff.map((user, index) => (
                                    <tr key={index}>
                                      {/* <td>{page > 1 ? (page - 1) * 10 + index + 1 : index + 1}</td> */}
                                      <td>{user._id}</td>
                                      <td>{user.username}</td>
                                      <td>
                                        <button
                                          disabled={deleteStaffId.id === user._id}
                                          className="btn btn-secondary"
                                          onClick={() => {
                                            setDeleteStaff({ id: user._id });
                                          }}
                                        >
                                          {}
                                          <em class="icon ni ni-trash-fill "></em>
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <>
                              <div className="row g-gs">
                                {staff.map((user, index) => (
                                  <Card user={user} />
                                ))}
                              </div>
                            </>
                          )}

                          {/* <div className="d-flex justify-content-end mt-5">
                            <nav>
                              <ul className="pagination">
                                <li className={page === 1 ? "page-item disabled" : "page-item"}>
                                  <Link
                                    className="page-link"
                                    to=""
                                    onClick={(e) => {
                                      setPage(page - 1);
                                    }}
                                  >
                                    Prev
                                  </Link>
                                </li>

                                <li className="page-item active" aria-current="page">
                                  <Link className="page-link" to="">
                                    {page} <span className="sr-only">(current)</span>
                                  </Link>
                                </li>

                                <li className={page === totalPages ? "page-item disabled" : "page-item"}>
                                  <Link
                                    className="page-link"
                                    to=""
                                    tabIndex={-1}
                                    aria-disabled={page === totalPages ? true : false}
                                    onClick={(e) => {
                                      setPage(page + 1);
                                    }}
                                  >
                                    Next
                                  </Link>
                                </li>
                              </ul>
                            </nav>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* new staff */}

      <div className="modal fade" tabIndex={-1} id="add">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Create new staff member</h5>
              <button id="modalClose" className="close" data-dismiss="modal" aria-label="Close">
                <em className="icon ni ni-cross" />
              </button>
            </div>
            <div className="modal-body">
              {message}
              <form onSubmit={createStaff} className="form-validate is-alter">
                <div className="form-group">
                  <label className="form-label" htmlFor="username">
                    Username
                  </label>
                  <input type="text" name="username" placeholder="Enter the username" className="form-control form-control-lg" />
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="password">
                    Password
                  </label>
                  <input type="text" name="password" placeholder="Enter the password" className="form-control form-control-lg" />
                </div>

                <div className="form-group d-flex justify-content-end">
                  <button disabled={loader} className="btn btn-lg btn-primary px-5" type="submit">
                    Create {loader}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Staff;
