import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { UserContext } from "../App";

const Header = (props) => {
  const userContextValue = useContext(UserContext);
  return (
    <div className="nk-header nk-header-fixed is-light">
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger d-xl-none ml-n1">
            <a
              href="#"
              className="nk-nav-toggle nk-quick-nav-icon"
              onClick={() => props.sidebarRef.current?.classList.toggle("nk-sidebar-active")}
            >
              <em className="icon ni ni-menu" />
            </a>
          </div>
          <div className="nk-header-brand d-xl-none">
            <a href="/" className="logo-link">
              <img className="logo-dark logo-img" src="/logo.svg" alt="logo-dark" />
            </a>
          </div>

          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              <li className="dropdown user-dropdown">
                <a href="#" className="dropdown-toggle mr-n1" data-toggle="dropdown">
                  <div className="user-toggle">
                    <div className="user-avatar sm">
                      <em className="icon ni ni-user-alt" />
                    </div>
                    <div className="user-info d-none d-xl-block">
                      <div className="user-name dropdown-indicator">{userContextValue.user.username}</div>
                    </div>
                  </div>
                </a>
                <div className="dropdown-menu dropdown-menu-md dropdown-menu-right">
                  <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                    <div className="user-card">
                      <div className="user-avatar">
                        <span>A</span>
                      </div>
                      <div className="user-info">
                        <span className="lead-text">{userContextValue.user.username}</span>
                      </div>
                    </div>
                  </div>
                  <div className="dropdown-inner">
                    {/* <ul className="link-list">
                      <li>
                        <Link to="/admin-settings">
                          <em className="icon ni ni-setting-alt" />
                          <span>Account Setting</span>
                        </Link>
                      </li>
                    </ul> */}
                  </div>
                  <div className="dropdown-inner">
                    <ul className="link-list">
                      <li>
                        <Link
                          to=""
                          onClick={() => {
                            Cookies.remove("footprint");
                            window.location.reload();
                          }}
                        >
                          <em className="icon ni ni-signout" />
                          <span>Sign out</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {/* .nk-header-wrap */}
      </div>
      {/* .container-fliud */}
    </div>
  );
};

export default Header;
