import React, { useContext } from "react";
import { useRoutes } from "react-router-dom";
import Dashboard from "../source/Dashboard";
import Orders from "../source/Orders";
import Users from "../source/Users";
import UserData from "../source/User/UserData";
import Staff from "../source/Staff";
import Pricing from "../source/Pricing";
import SingleType from "../source/Components/SingleType";
import Invoices from "../source/Invoices";
import CustomPricing from "../source/Components/CustomPricing";
import USPSscansheet from "../source/USPSscansheet";
import Website from "../source/Website";
import BulkOrders from "../source/BulkOrders";
import Logs from "../source/Logs";
import USPSTracking from "../source/USPSTracking";
import { UserContext } from "../App";
import UsersApproval from "../source/UsersApproval";

const DashboardRoutes = (props) => {
  const userContextValue = useContext(UserContext);
  return useRoutes([
    userContextValue.user.role !== "staff" && {
      path: "",
      element: <Dashboard sidebarRef={props.sidebarRef} />,
    },
    userContextValue.user.role !== "staff" && {
      path: "/scansheet",
      element: <USPSscansheet sidebarRef={props.sidebarRef} />,
    },
    {
      path: "/orders",
      element: <Orders sidebarRef={props.sidebarRef} />,
    },
    {
      path: "/bulk-orders",
      element: <BulkOrders sidebarRef={props.sidebarRef} />,
    },
    userContextValue.user.role !== "staff" && {
      path: "/transaction",
      element: <Logs sidebarRef={props.sidebarRef} />,
    },
    userContextValue.user.role !== "staff" && {
      path: "/usps",
      element: <USPSTracking sidebarRef={props.sidebarRef} />,
    },

    {
      path: "/users",
      element: <Users sidebarRef={props.sidebarRef} />,
    },
    {
      path: "/users/:id",
      element: <UserData sidebarRef={props.sidebarRef} />,
    },

    {
      path: "/new-users",
      element: <UsersApproval sidebarRef={props.sidebarRef} />,
    },
    userContextValue.user.role !== "staff" && {
      path: "/staff",
      element: <Staff sidebarRef={props.sidebarRef} />,
    },

    {
      path: "/users/customPricing/:id",
      element: <CustomPricing sidebarRef={props.sidebarRef} />,
    },
    {
      path: "/invoices",
      element: <Invoices sidebarRef={props.sidebarRef} />,
    },

    userContextValue.user.role !== "staff" && {
      path: "/pricing",
      element: <Pricing sidebarRef={props.sidebarRef} />,
    },
    userContextValue.user.role !== "staff" && {
      path: "/pricing/:id",
      element: <SingleType sidebarRef={props.sidebarRef} />,
    },
    userContextValue.user.role !== "staff" && {
      path: "/website",
      element: <Website sidebarRef={props.sidebarRef} />,
    },
  ]);
};

export default DashboardRoutes;
