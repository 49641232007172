import Loader from "./Loader";
export default function PageLoader() {
  return (
    <div
      className="loader text-center"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
      }}
    >
      <Loader className="spinner-border-lg" />
    </div>
  );
}
